import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks } from '../common';
import { Playground, Props } from 'docz';
import { Button, Modal, ModalForm, ModalInfo, ModalOverlay, ModalStatus, Size, Space, TextArea } from '@hse-design/react';
import { useState } from 'react';
import doneImage from '@hse-design/core/lib/assets/images/done.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "modal"
    }}>{`Modal`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { ModalForm, ModalInfo, ModalStatus } from '@hse-design/react'
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=4137%3A8525'} storybook={'/?path=/story/modal-modalform--playground'} vueStorybook={'/?path=/story/modal-modalform--playground'} name={'Modal'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Modal – это компонент модального окна.`}</p>
    <p>{`Есть несколько вариаций компонента для различного использования:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`абстрактный компонент Modal для создания произвольных всплывающих окон;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ModalForm для создания окон с формами и кнопками действий;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ModalStatus для информационных сообщений;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ModalInfo для произвольного контента.`}</p>
      </li>
    </ul>
    <p>{`Компонент Modal рассчитан на работу с библиотекой `}<a parentName="p" {...{
        "href": "https://github.com/reactjs/react-modal"
      }}><inlineCode parentName="a">{`react-modal`}</inlineCode></a>{`.
Чтобы установить библиотеку, проделайте следующие шаги:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Установить зависимость `}<inlineCode parentName="p">{`react-modal`}</inlineCode>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add react-modal
# or
npm i react-modal
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`В точке входа в приложение вызвать `}<inlineCode parentName="p">{`Modal.setAppElement(<selector>)`}</inlineCode>{` в соответствии
с документацией `}<a parentName="p" {...{
            "href": "http://reactcommunity.org/react-modal/accessibility/#app-element"
          }}><inlineCode parentName="a">{`react-modal`}</inlineCode></a>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import ReactModal from "react-modal";

const root = document.getElementById('root');

ReactModal.setAppElement(root);

ReactDOM.render(
  <App />,
  root
);
`}</code></pre>
      </li>
    </ol>
    <p>{`Так как компонент Modal использует react-modal, то доступны все `}<a parentName="p" {...{
        "href": "http://reactcommunity.org/react-modal/"
      }}>{`библиотечные свойства`}</a>{`.`}</p>
    <h2 {...{
      "id": "modaloverlay"
    }}>{`ModalOverlay`}</h2>
    <p>{`Компонент подложки модального окна.
Затемняет фон, занимает все пространство окна, имеет фиксированное позиционирование.`}</p>
    <p>{`В обычной ситуации использование ModalOverlay не требуется, так как он автоматически рендерится компонентом Modal.`}</p>
    <p>{`В примере ниже компонент уменьшен и статично позиционирован для демонстрации.`}</p>
    <Playground __position={1} __code={'<ModalOverlay\n  style={{ position: \'static\', width: \'150px\', height: \'150px\' }}\n/>'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      Playground,
      Props,
      Button,
      Modal,
      ModalForm,
      ModalInfo,
      ModalOverlay,
      ModalStatus,
      Size,
      Space,
      TextArea,
      useState,
      doneImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ModalOverlay style={{
        position: 'static',
        width: '150px',
        height: '150px'
      }} mdxType="ModalOverlay" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "modalform"
    }}>{`ModalForm`}</h3>
    <p>{`Модальное окно с формой. Содержит следующие части:`}</p>
    <ol>
      <li parentName="ol">{`Заголовок`}</li>
      <li parentName="ol">{`Контент`}</li>
      <li parentName="ol">{`Действия (кнопки)`}</li>
    </ol>
    <p>{`Наполнение контента модального окна может быть любым`}</p>
    <Playground __position={2} __code={'() => {\n  const [isOpen, setIsOpen] = useState(false)\n  const [value, setValue] = useState(\'\')\n  const [loading, setLoading] = useState(false)\n  const send = () => {\n    setLoading(true)\n    setTimeout(() => {\n      setIsOpen(false)\n      setLoading(false)\n      setValue(\'\')\n    }, 400)\n  }\n  const close = () => setIsOpen(false)\n  return (\n    <div>\n      <Button onClick={() => setIsOpen(true)}>Open ModalForm</Button>\n      <ModalForm\n        title=\"Напишите ответ\"\n        isOpen={isOpen}\n        actions={\n          <Button spinner={loading} onClick={send}>\n            Отправить\n          </Button>\n        }\n        onAfterOpen={() => console.log(\'open\')}\n        onAfterClose={() => console.log(\'closed\')}\n        onCloseClick={close}\n      >\n        <div>\n          Напишите ответ текстом\n          <Space size={Size.small_x} vertical />\n        </div>\n        <TextArea\n          value={value}\n          style={{ height: \'176px\' }}\n          autosize={true}\n          fullWidth={true}\n          placeholder=\"Напишите текст...\"\n          onChange={e => setValue(e.target.value)}\n        />\n      </ModalForm>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      Playground,
      Props,
      Button,
      Modal,
      ModalForm,
      ModalInfo,
      ModalOverlay,
      ModalStatus,
      Size,
      Space,
      TextArea,
      useState,
      doneImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = useState(false);
        const [value, setValue] = useState('');
        const [loading, setLoading] = useState(false);

        const send = () => {
          setLoading(true);
          setTimeout(() => {
            setIsOpen(false);
            setLoading(false);
            setValue('');
          }, 400);
        };

        const close = () => setIsOpen(false);

        return <div>
        <Button onClick={() => setIsOpen(true)} mdxType="Button">
          Open ModalForm
        </Button>
        <ModalForm title='Напишите ответ' isOpen={isOpen} actions={<Button spinner={loading} onClick={send} mdxType="Button">
              Отправить
            </Button>} onAfterOpen={() => console.log('open')} onAfterClose={() => console.log('closed')} onCloseClick={close} mdxType="ModalForm">
          <div>
            Напишите ответ текстом
            <Space size={Size.small_x} vertical mdxType="Space" />
          </div>
          <TextArea value={value} style={{
              height: '176px'
            }} autosize={true} fullWidth={true} placeholder='Напишите текст...' onChange={e => setValue(e.target.value)} mdxType="TextArea" />
        </ModalForm>
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "modalinfo"
    }}>{`ModalInfo`}</h3>
    <p>{`Модальное окно с информацией. Содержит следующие части:`}</p>
    <ol>
      <li parentName="ol">{`Заголовок`}</li>
      <li parentName="ol">{`Информирующий текст`}</li>
      <li parentName="ol">{`Возможно использование любых дополнительных элементов, которые помогают отображать информацию лучшим образом (иконки, разделители и другое)`}</li>
    </ol>
    <Playground __position={3} __code={'() => {\n  const [isOpen, setIsOpen] = useState(false)\n  const close = () => setIsOpen(false)\n  return (\n    <div>\n      <Button onClick={() => setIsOpen(true)}>Open ModalInfo</Button>\n      <ModalInfo\n        title=\"Lorem Ipsum\"\n        isOpen={isOpen}\n        onAfterOpen={() => console.log(\'open\')}\n        onAfterClose={() => console.log(\'closed\')}\n        onCloseClick={close}\n      >\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non\n        lorem cursus, placerat odio vel, molestie ante. Vestibulum vitae diam\n        id elit venenatis vehicula vel quis tortor. Integer commodo erat\n        lectus, ut posuere enim fermentum ac. Nunc commodo nibh quam, in\n        malesuada diam iaculis nec. Vestibulum sed ex ac est pulvinar\n        condimentum. Curabitur sodales maximus ipsum. Nam dapibus mattis\n        scelerisque. Sed a tincidunt tellus, ut blandit metus. Quisque\n        laoreet, orci vitae rutrum varius, sapien ipsum mollis arcu, nec\n        commodo dui nisl id ex. Pellentesque elit odio, posuere at erat vitae,\n        tristique viverra tortor. Praesent bibendum, magna id convallis\n        scelerisque, nibh ipsum tincidunt libero, et placerat velit nisi\n        lobortis lectus. Nunc convallis tortor ut turpis cursus luctus. Fusce\n        dui enim, malesuada at odio gravida, interdum dictum neque. In hac\n        habitasse platea dictumst. Mauris leo dolor, suscipit vitae suscipit\n        et, consectetur et sem. Vestibulum vestibulum leo ligula, vitae\n        molestie neque tincidunt et.\n      </ModalInfo>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      Playground,
      Props,
      Button,
      Modal,
      ModalForm,
      ModalInfo,
      ModalOverlay,
      ModalStatus,
      Size,
      Space,
      TextArea,
      useState,
      doneImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = useState(false);

        const close = () => setIsOpen(false);

        return <div>
        <Button onClick={() => setIsOpen(true)} mdxType="Button">
          Open ModalInfo
        </Button>
        <ModalInfo title='Lorem Ipsum' isOpen={isOpen} onAfterOpen={() => console.log('open')} onAfterClose={() => console.log('closed')} onCloseClick={close} mdxType="ModalInfo">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non lorem cursus, placerat odio vel, molestie
          ante. Vestibulum vitae diam id elit venenatis vehicula vel quis tortor. Integer commodo erat lectus, ut
          posuere enim fermentum ac. Nunc commodo nibh quam, in malesuada diam iaculis nec. Vestibulum sed ex ac est
          pulvinar condimentum. Curabitur sodales maximus ipsum. Nam dapibus mattis scelerisque. Sed a tincidunt tellus,
          ut blandit metus. Quisque laoreet, orci vitae rutrum varius, sapien ipsum mollis arcu, nec commodo dui nisl id
          ex. Pellentesque elit odio, posuere at erat vitae, tristique viverra tortor. Praesent bibendum, magna id
          convallis scelerisque, nibh ipsum tincidunt libero, et placerat velit nisi lobortis lectus. Nunc convallis
          tortor ut turpis cursus luctus. Fusce dui enim, malesuada at odio gravida, interdum dictum neque. In hac
          habitasse platea dictumst. Mauris leo dolor, suscipit vitae suscipit et, consectetur et sem. Vestibulum
          vestibulum leo ligula, vitae molestie neque tincidunt et.
        </ModalInfo>
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "modalstatus"
    }}>{`ModalStatus`}</h3>
    <p>{`Модальное окно со статусом. Содержит следующие части:`}</p>
    <ol>
      <li parentName="ol">{`Иллюстрацию`}</li>
      <li parentName="ol">{`Информирующий текст`}</li>
      <li parentName="ol">{`Возможно использование любых дополнительных элементов, которые помогают отображать информацию лучшим образом (иконки, разделители и другое)`}</li>
    </ol>
    <Playground __position={4} __code={'() => {\n  const [isOpen, setIsOpen] = useState(false)\n  const close = () => setIsOpen(false)\n  return (\n    <div>\n      <Button onClick={() => setIsOpen(true)}>Open ModalStatus</Button>\n      <ModalStatus\n        title=\"Спасибо, что прошли анкету!\"\n        isOpen={isOpen}\n        actions={<Button onClick={close}>Хорошо</Button>}\n        icon={<img src={doneImage} />}\n        onAfterOpen={() => console.log(\'open\')}\n        onAfterClose={() => console.log(\'closed\')}\n        onCloseClick={close}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      Playground,
      Props,
      Button,
      Modal,
      ModalForm,
      ModalInfo,
      ModalOverlay,
      ModalStatus,
      Size,
      Space,
      TextArea,
      useState,
      doneImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = useState(false);

        const close = () => setIsOpen(false);

        return <div>
        <Button onClick={() => setIsOpen(true)} mdxType="Button">
          Open ModalStatus
        </Button>
        <ModalStatus title='Спасибо, что прошли анкету!' isOpen={isOpen} actions={<Button onClick={close} mdxType="Button">Хорошо</Button>} icon={<img src={doneImage} />} onAfterOpen={() => console.log('open')} onAfterClose={() => console.log('closed')} onCloseClick={close} mdxType="ModalStatus" />
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "modal-1"
    }}>{`Modal`}</h3>
    <p>{`Абстрактное модальное окно для произвольного контента, который не удаётся реализовать с помощью ModalForm.`}</p>
    <Playground __position={5} __code={'() => {\n  const [isOpen, setIsOpen] = useState(false)\n  const close = () => setIsOpen(false)\n  return (\n    <div>\n      <Button onClick={() => setIsOpen(true)}>Open Modal</Button>\n      <Modal\n        title=\"Напишите ответ\"\n        isOpen={isOpen}\n        onAfterOpen={() => console.log(\'open\')}\n        onAfterClose={() => console.log(\'closed\')}\n        onCloseClick={close}\n      >\n        Custom content\n      </Modal>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      Playground,
      Props,
      Button,
      Modal,
      ModalForm,
      ModalInfo,
      ModalOverlay,
      ModalStatus,
      Size,
      Space,
      TextArea,
      useState,
      doneImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = useState(false);

        const close = () => setIsOpen(false);

        return <div>
        <Button onClick={() => setIsOpen(true)} mdxType="Button">
          Open Modal
        </Button>
        <Modal title='Напишите ответ' isOpen={isOpen} onAfterOpen={() => console.log('open')} onAfterClose={() => console.log('closed')} onCloseClick={close} mdxType="Modal">
          Custom content
        </Modal>
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "произвольные-размеры"
    }}>{`Произвольные размеры`}</h3>
    <p>{`Вы можете задать произвольный размер модальному окну. Для этого передайте необходимые стили в проп `}<inlineCode parentName="p">{`style`}</inlineCode>{`, либо передайте класс в проп `}<inlineCode parentName="p">{`className`}</inlineCode></p>
    <Playground __position={6} __code={'() => {\n  const [isOpen, setIsOpen] = useState(false)\n  const close = () => setIsOpen(false)\n  return (\n    <div>\n      <Button onClick={() => setIsOpen(true)}>Open ModalStatus</Button>\n      <ModalStatus\n        style={{ maxWidth: \'330px\' }}\n        title=\"Спасибо, что прошли анкету!\"\n        isOpen={isOpen}\n        actions={<Button onClick={close}>Хорошо</Button>}\n        icon={<img src={doneImage} />}\n        onAfterOpen={() => console.log(\'open\')}\n        onAfterClose={() => console.log(\'closed\')}\n        onCloseClick={close}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      Playground,
      Props,
      Button,
      Modal,
      ModalForm,
      ModalInfo,
      ModalOverlay,
      ModalStatus,
      Size,
      Space,
      TextArea,
      useState,
      doneImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = useState(false);

        const close = () => setIsOpen(false);

        return <div>
        <Button onClick={() => setIsOpen(true)} mdxType="Button">
          Open ModalStatus
        </Button>
        <ModalStatus style={{
            maxWidth: '330px'
          }} title='Спасибо, что прошли анкету!' isOpen={isOpen} actions={<Button onClick={close} mdxType="Button">Хорошо</Button>} icon={<img src={doneImage} />} onAfterOpen={() => console.log('open')} onAfterClose={() => console.log('closed')} onCloseClick={close} mdxType="ModalStatus" />
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы-modalform"
    }}>{`Пропы ModalForm`}</h2>
    <Props of={ModalForm} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на компонент `}<inlineCode parentName="p">{`ReactModal`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "пропы-modalstatus"
    }}>{`Пропы ModalStatus`}</h2>
    <Props of={ModalStatus} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на компонент `}<inlineCode parentName="p">{`ReactModal`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "пропы-modalinfo"
    }}>{`Пропы ModalInfo`}</h2>
    <Props of={ModalInfo} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на компонент `}<inlineCode parentName="p">{`ReactModal`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "пропы-modal"
    }}>{`Пропы Modal`}</h2>
    <Props of={Modal} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на компонент `}<inlineCode parentName="p">{`ReactModal`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "пропы-modaloverlay"
    }}>{`Пропы ModalOverlay`}</h2>
    <p>{`Те же самые, что и у обычного `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      